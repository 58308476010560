import React from 'react';
import { Button } from 'react-bootstrap';
import { saveCaseReport } from 'redux/actions/cases/caseReportsActions';

export const clientGetReport = (code) => {
    return saveCaseReport(code, true, true);
};

const reportFormatter = (row) => {
    const { code } = row;
    const visibility = 'visible';
    const onClick = () => saveCaseReport(code, true, true);
    return (
      <Button bsSize="xsmall" title="Download Report" onClick={onClick} style={{ visibility }}>
            <span className="fa fa-file" style={{ fontSize: '1.3em', pointerEvents: 'none' }}/>
      </Button>
    );
};

export const colsKeys = [
    {
        isKey: true,
        name: 'code',
        title: 'Code',
        sort: false,
        hidden: true,
    },
    {
        name: 'caseId',
        title: 'MedRev #',
        columnTitle: true,
        sort: true,
        filter: { type: 'TextFilter', delay: 500 },
    },
];

export const c_number = {
    name: 'number',
    title: 'Case #',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_number_DRAFT = {
    name: 'number',
    title: 'Case #',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_number_DISPATCH = {
    name: 'number',
    title: 'Case #',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_number_CLOSED = {
    name: 'number',
    title: 'Case #',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_client = {
    name: 'client',
    title: 'Client',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_client_DRAFT = {
    name: 'client',
    title: 'Client',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_client_DISPATCH = {
    name: 'client',
    title: 'Client',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_client_REVIEW = {
    name: 'client',
    title: 'Client',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_client_QA = {
    name: 'client',
    title: 'Client',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_client_CLOSED = {
    name: 'client',
    title: 'Client',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_client_CLOSED1 = {
    name: 'client',
    title: 'Client',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_client_CLOSED2 = {
    name: 'client',
    title: 'Client',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_case_type = {
    name: 'patientCaseType',
    title: 'Case Type',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_case_type_DRAFT = {
    name: 'patientCaseType',
    title: 'Case Type',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_case_type_DISPATCH = {
    name: 'patientCaseType',
    title: 'Case Type',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_case_type_REVIEW = {
    name: 'patientCaseType',
    title: 'Case Type',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_case_type_QA = {
    name: 'patientCaseType',
    title: 'Case Type',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_case_type_CLOSED = {
    name: 'patientCaseType',
    title: 'Case Type',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_case_type_CLOSED1 = {
    name: 'patientCaseType',
    title: 'Case Type',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_case_type_CLOSED2 = {
    name: 'patientCaseType',
    title: 'Case Type',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_pages = {
    name: 'pages',
    title: 'Pages',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_pages_DRAFT = {
    name: 'pages',
    title: 'Pages',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_pages_DISPATCH = {
    name: 'pages',
    title: 'Pages',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_pages_REVIEW = {
    name: 'pages',
    title: 'Pages',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_pages_QA = {
    name: 'pages',
    title: 'Pages',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_pages_CLOSED = {
    name: 'pages',
    title: 'Pages',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_pages_CLOSED1 = {
    name: 'pages',
    title: 'Pages',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_pages_CLOSED2 = {
    name: 'pages',
    title: 'Pages',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_in_use_by = {
    name: 'inUseBy',
    title: 'In Use By',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_in_use_by_DRAFT = {
    name: 'inUseBy',
    title: 'In Use By',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_in_use_by_DISPATCH = {
    name: 'inUseBy',
    title: 'In Use By',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_in_use_by_REVIEW = {
    name: 'inUseBy',
    title: 'In Use By',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_in_use_by_QA = {
    name: 'inUseBy',
    title: 'In Use By',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_in_use_by_CLOSED = {
    name: 'inUseBy',
    title: 'In Use By',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_submitter = {
    name: 'submitterFullName',
    title: 'Submitter',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_submitter_DRAFT = {
    name: 'submitterFullName',
    title: 'Submitter',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_submitter_DISPATCH = {
    name: 'submitterFullName',
    title: 'Submitter',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_submitter_REVIEW = {
    name: 'submitterFullName',
    title: 'Submitter',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_submitter_QA = {
    name: 'submitterFullName',
    title: 'Submitter',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_submitter_CLOSED = {
    name: 'submitterFullName',
    title: 'Submitter',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_dispatcher = {
    name: 'dispatcherFullName',
    title: 'Dispatcher',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_dispatcher_DRAFT = {
    name: 'dispatcherFullName',
    title: 'Dispatcher',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_dispatcher_DISPATCH = {
    name: 'dispatcherFullName',
    title: 'Dispatcher',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_dispatcher_REVIEW = {
    name: 'dispatcherFullName',
    title: 'Dispatcher',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_dispatcher_QA = {
    name: 'dispatcherFullName',
    title: 'Dispatcher',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_dispatcher_CLOSED = {
    name: 'dispatcherFullName',
    title: 'Dispatcher',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_dispatcher_CLOSED1 = {
    name: 'dispatcherFullName',
    title: 'Dispatcher',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_dispatcher_CLOSED2 = {
    name: 'dispatcherFullName',
    title: 'Dispatcher',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_reviewer = {
    name: 'reviewerFullName',
    title: 'Reviewer',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_reviewer_DRAFT = {
    name: 'reviewerFullName',
    title: 'Reviewer',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_reviewer_DISPATCH = {
    name: 'reviewerFullName',
    title: 'Reviewer',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_reviewer_REVIEW = {
    name: 'reviewerFullName',
    title: 'Reviewer',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_reviewer_QA = {
    name: 'reviewerFullName',
    title: 'Reviewer',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_reviewer_CLOSED = {
    name: 'reviewerFullName',
    title: 'Reviewer',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_stage = {
    name: 'stage',
    title: 'Stage',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_stage_DRAFT = {
    name: 'stage',
    title: 'Stage',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_stage_DISPATCH = {
    name: 'stage',
    title: 'Stage',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_stage_REVIEW = {
    name: 'stage',
    title: 'Stage',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_stage_QA = {
    name: 'stage',
    title: 'Stage',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_create_date_DRAFT = {
    name: 'creationDate',
    title: 'Create Date',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_due_date_DRAFT = {
    name: 'clientDueDate',
    title: 'Due Date',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_due_date_DISPATCH = {
    name: 'clientDueDate',
    title: 'Due Date',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_due_date_REVIEW = {
    name: 'clientDueDate',
    title: 'Due Date',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_due_date_QA = {
    name: 'clientDueDate',
    title: 'Due Date',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_reviewer_due_date = {
    name: 'reviewerDueDate',
    title: 'Reviewer Due Date',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_reviewer_due_date_DRAFT = {
    name: 'reviewerDueDate',
    title: 'Reviewer Due Date',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_reviewer_due_date_DISPATCH = {
    name: 'reviewerDueDate',
    title: 'Reviewer Due Date',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_reviewer_due_date_REVIEW = {
    name: 'reviewerDueDate',
    title: 'Reviewer Due Date',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_reviewer_due_date_QA = {
    name: 'reviewerDueDate',
    title: 'Reviewer Due Date',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_reviewer_due_date_CLOSED = {
    name: 'reviewerDueDate',
    title: 'Reviewer Due Date',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_submit_date = {
    name: 'submitDate',
    title: 'Submit Date',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_submit_date_DISPATCH = {
    name: 'submitDate',
    title: 'Submit Date',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_submit_date_REVIEW = {
    name: 'submitDate',
    title: 'Submit Date',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_submit_date_QA = {
    name: 'submitDate',
    title: 'Submit Date',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_submit_date_CLOSED = {
    name: 'submitDate',
    title: 'Submit Date',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_complete_date = {
    name: 'completionDate',
    title: 'Complete Date',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_complete_date_CLOSED = {
    name: 'completionDate',
    title: 'Complete Date',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_complete_date_CLOSED1 = {
    name: 'completionDate',
    title: 'Complete Date',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_complete_date_CLOSED2 = {
    name: 'completionDate',
    title: 'Complete Date',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_report = {
    name: 'report',
    title: 'Report',
    dataFormat: reportFormatter,
    columnTitle: () => 'Download Report',
    columnClassName: 'overflow-visible',
    dataAlign: 'center',
};
export const c_reviewer_time = {
    name: 'reviewerTime',
    title: 'Reviewer Time',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_reviewer_time_CLOSED = {
    name: 'reviewerTime',
    title: 'Reviewer Time',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_reviewer_time_CLOSED1 = {
    name: 'reviewerTime',
    title: 'Reviewer Time',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_reviewer_time_CLOSED2 = {
    name: 'reviewerTime',
    title: 'Reviewer Time',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};
export const c_payment = {
    name: 'payment',
    title: 'Payment',
    columnTitle: true,
    sort: true,
    filter: { type: 'TextFilter', delay: 500 },
};

export const colsStaffDraft = [
    {
        isKey: true,
        name: 'code',
        title: 'Code',
        sort: false,
        hidden: true,
    },
    {
        name: 'caseId',
        title: 'MedRev #',
        columnTitle: true,
        sort: true,
        filter: { type: 'TextFilter', delay: 500 },
    },
    c_client_DRAFT,
    c_case_type_DRAFT,
    c_pages_DRAFT,
    c_create_date_DRAFT,
    c_reviewer_due_date_DRAFT,
    c_due_date_DRAFT,
    c_in_use_by_DRAFT,
    c_submitter_DRAFT,
    c_dispatcher_DRAFT,
    c_reviewer_DRAFT,
    c_stage_DRAFT,
];

export const colsStaffDispatch = [
    {
        isKey: true,
        name: 'code',
        title: 'Code',
        sort: false,
        hidden: true,
    },
    {
        name: 'caseId',
        title: 'MedRev #',
        columnTitle: true,
        sort: true,
        filter: { type: 'TextFilter', delay: 500 },
    },
    c_client_DISPATCH,
    c_case_type_DISPATCH,
    c_pages_DISPATCH,
    c_submit_date_DISPATCH,
    c_reviewer_due_date_DISPATCH,
    c_due_date_DISPATCH,
    c_in_use_by_DISPATCH,
    c_submitter_DISPATCH,
    c_dispatcher_DISPATCH,
    c_reviewer_DISPATCH,
    c_stage_DISPATCH,
];

export const colsStaffReview = [
    {
        isKey: true,
        name: 'code',
        title: 'Code',
        sort: false,
        hidden: true,
    },
    {
        name: 'caseId',
        title: 'MedRev #',
        columnTitle: true,
        sort: true,
        filter: { type: 'TextFilter', delay: 500 },
    },
    c_client_REVIEW,
    c_case_type_REVIEW,
    c_pages_REVIEW,
    c_submit_date_REVIEW,
    c_reviewer_due_date_REVIEW,
    c_due_date_REVIEW,
    c_in_use_by_REVIEW,
    c_submitter_REVIEW,
    c_dispatcher_REVIEW,
    c_reviewer_REVIEW,
    c_stage_REVIEW,
];

export const colsStaffQA = [
    {
        isKey: true,
        name: 'code',
        title: 'Code',
        sort: false,
        hidden: true,
    },
    {
        name: 'caseId',
        title: 'MedRev #',
        columnTitle: true,
        sort: true,
        filter: { type: 'TextFilter', delay: 500 },
    },
    c_client_QA,
    c_case_type_QA,
    c_pages_QA,
    c_submit_date_QA,
    c_reviewer_due_date_QA,
    c_due_date_QA,
    c_in_use_by_QA,
    c_submitter_QA,
    c_dispatcher_QA,
    c_reviewer_QA,
    c_stage_QA,
];

export const colsStaffClosed = [
    {
        isKey: true,
        name: 'code',
        title: 'Code',
        sort: false,
        hidden: true,
    },
    {
        name: 'caseId',
        title: 'MedRev #',
        columnTitle: true,
        sort: true,
        filter: { type: 'TextFilter', delay: 500 },
    },
    c_client_CLOSED,
    c_case_type_CLOSED,
    c_pages_CLOSED,
    c_submit_date_CLOSED,
    c_reviewer_due_date_CLOSED,
    c_complete_date_CLOSED,
    c_in_use_by_CLOSED,
    c_submitter_CLOSED,
    c_dispatcher_CLOSED,
    c_reviewer_CLOSED,
    c_report,
];

export const colsStaffAll = [
    {
        isKey: true,
        name: 'code',
        title: 'Code',
        sort: false,
        hidden: true,
    },
    {
        name: 'caseId',
        title: 'MedRev #',
        columnTitle: true,
        sort: true,
        filter: { type: 'TextFilter', delay: 500 },
    },
    c_client,
    c_case_type,
    c_pages,
    c_submit_date,
    c_complete_date,
    c_in_use_by,
    c_submitter,
    c_dispatcher,
    c_reviewer,
    c_stage,
    c_report,
];

export const colsReviewerAvailable = [
    {
        isKey: true,
        name: 'code',
        title: 'Code',
        sort: false,
        hidden: true,
    },
    {
        name: 'caseId',
        title: 'MedRev #',
        columnTitle: true,
        sort: true,
        filter: { type: 'TextFilter', delay: 500 },
    },
    c_client_DRAFT,
    c_case_type_DRAFT,
    c_dispatcher_DRAFT,
    c_pages_DRAFT,
    c_reviewer_due_date_DRAFT,
];

export const colsReviewerAccepted = [
    {
        isKey: true,
        name: 'code',
        title: 'Code',
        sort: false,
        hidden: true,
    },
    {
        name: 'caseId',
        title: 'MedRev #',
        columnTitle: true,
        sort: true,
        filter: { type: 'TextFilter', delay: 500 },
    },
    c_client_DISPATCH,
    c_case_type_DISPATCH,
    c_dispatcher_DISPATCH,
    c_pages_DISPATCH,
    c_reviewer_due_date_DISPATCH,
];

export const colsReviewerQA = [
    {
        isKey: true,
        name: 'code',
        title: 'Code',
        sort: false,
        hidden: true,
    },
    {
        name: 'caseId',
        title: 'MedRev #',
        columnTitle: true,
        sort: true,
        filter: { type: 'TextFilter', delay: 500 },
    },
    c_client_QA,
    c_case_type_QA,
    c_dispatcher_QA,
    c_pages_QA,
    c_reviewer_due_date_QA,
];

export const colsReviewerClosed = [
    {
        isKey: true,
        name: 'code',
        title: 'Code',
        sort: false,
        hidden: true,
    },
    {
        name: 'caseId',
        title: 'MedRev #',
        columnTitle: true,
        sort: true,
        filter: { type: 'TextFilter', delay: 500 },
    },
    c_client_CLOSED,
    c_case_type_CLOSED,
    c_dispatcher_CLOSED,
    c_pages_CLOSED,
    c_complete_date_CLOSED,
    c_reviewer_time_CLOSED,
    c_payment,
];

export const colsReviewerClosed1 = [
    {
        isKey: true,
        name: 'code',
        title: 'Code',
        sort: false,
        hidden: true,
    },
    {
        name: 'caseId',
        title: 'MedRev #',
        columnTitle: true,
        sort: true,
        filter: { type: 'TextFilter', delay: 500 },
    },
    c_client_CLOSED1,
    c_case_type_CLOSED1,
    c_dispatcher_CLOSED1,
    c_pages_CLOSED1,
    c_complete_date_CLOSED1,
    c_reviewer_time_CLOSED1,
    c_payment,
];

export const colsReviewerClosed2 = [
    {
        isKey: true,
        name: 'code',
        title: 'Code',
        sort: false,
        hidden: true,
    },
    {
        name: 'caseId',
        title: 'MedRev #',
        columnTitle: true,
        sort: true,
        filter: { type: 'TextFilter', delay: 500 },
    },
    c_client_CLOSED2,
    c_case_type_CLOSED2,
    c_dispatcher_CLOSED2,
    c_pages_CLOSED2,
    c_complete_date_CLOSED2,
    c_reviewer_time_CLOSED2,
    c_payment,
];

export const colsReviewerAll = [
    {
        isKey: true,
        name: 'code',
        title: 'Code',
        sort: false,
        hidden: true,
    },
    {
        name: 'caseId',
        title: 'MedRev #',
        columnTitle: true,
        sort: true,
        filter: { type: 'TextFilter', delay: 500 },
    },
    c_client,
    c_case_type,
    c_dispatcher,
    c_pages,
    c_complete_date,
    c_reviewer_time,
    c_payment,
];

export const colsReviewerFlagged = colsKeys.concat([c_client, c_case_type, c_dispatcher, c_pages, c_reviewer_due_date]);

export const colsClientDraft = [
    {
        isKey: true,
        name: 'code',
        title: 'Code',
        sort: false,
        hidden: true,
    },
    {
        name: 'caseId',
        title: 'MedRev #',
        columnTitle: true,
        sort: true,
        filter: { type: 'TextFilter', delay: 500 },
    },
    c_number_DRAFT,
    c_submitter_DRAFT,
    c_case_type_DRAFT,
    c_create_date_DRAFT,
    c_due_date_DRAFT,
];

export const colsClientInProgress = [
    {
        isKey: true,
        name: 'code',
        title: 'Code',
        sort: false,
        hidden: true,
    },
    {
        name: 'caseId',
        title: 'MedRev #',
        columnTitle: true,
        sort: true,
        filter: { type: 'TextFilter', delay: 500 },
    },
    c_number_DISPATCH,
    c_submitter_DISPATCH,
    c_case_type_DISPATCH,
    c_submit_date_DISPATCH,
    c_due_date_DISPATCH,
    c_stage_DISPATCH,
];

export const colsClientClosed = [
    {
        isKey: true,
        name: 'code',
        title: 'Code',
        sort: false,
        hidden: true,
    },
    {
        name: 'caseId',
        title: 'MedRev #',
        columnTitle: true,
        sort: true,
        filter: { type: 'TextFilter', delay: 500 },
    },
    c_number_CLOSED,
    c_submitter_CLOSED,
    c_case_type_CLOSED,
    c_submit_date_CLOSED,
    c_complete_date_CLOSED,
    c_report,
];

export const colsClientAll = [
    {
        isKey: true,
        name: 'code',
        title: 'Code',
        sort: false,
        hidden: true,
    },
    {
        name: 'caseId',
        title: 'MedRev #',
        columnTitle: true,
        sort: true,
        filter: { type: 'TextFilter', delay: 500 },
    },
    c_number,
    c_submitter,
    c_case_type,
    c_submit_date,
    c_complete_date,
    c_stage,
    c_report,
];

export const casesCols = {
    notKnown: colsKeys,

    draftStaff: colsStaffDraft,
    dispatchStaff: colsStaffDispatch,
    reviewStaff: colsStaffReview,
    qaStaff: colsStaffQA,
    completeStaff: colsStaffClosed,
    closedStaff: colsStaffClosed,
    all_casesStaff: colsStaffAll,
    flaggedStaff: colsStaffAll,

    draftClient: colsClientDraft,
    in_progressClient: colsClientInProgress,
    in_processClient: colsClientInProgress,
    completeClient: colsClientClosed,
    closedClient: colsClientClosed,
    all_casesClient: colsClientAll,
    flaggedClient: colsClientAll,

    availableReviewer: colsReviewerAvailable,
    acceptedReviewer: colsReviewerAccepted,
    qaReviewer: colsReviewerQA,
    closed_casesReviewer: colsReviewerClosed,
    closed_cases_this_monthReviewer: colsReviewerClosed1,
    closed_cases_last_monthReviewer: colsReviewerClosed2,
    all_casesReviewer: colsReviewerAll,
    flaggedReviewer: colsReviewerFlagged,
};
