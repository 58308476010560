import moment from 'moment';

const formatDate = (value) => {
    if (!value) return '';
    return moment.unix(value / 1000).utc(false).format('MM/DD/YYYY HH:mm:ss');
};

const patientCaseColumns = {
    draft: {

        staff: {
            caseId: { label: 'MedRev #' },
            client: { label: 'Client' },
            patientCaseType: { label: 'Case Type' },
            pages: { label: 'Pages' },
            creationDate: { label: 'Create Date', format: formatDate },
            reviewerDueDate: { label: 'Reviewer Due Date', format: formatDate },
            clientDueDate: { label: 'Due Date', format: formatDate },
            inUseByFullName: { label: 'In Use By' },
            submitterFullName: { label: 'Submitter' },
            dispatcherFullName: { label: 'Dispatcher' },
            reviewerFullName: { label: 'Reviewer' },
            suggestedSpecialty: { label: 'Suggested specialty' },
            patientFullName: { label: 'Patient Name' },
        },
        client: {
            caseId: { label: 'MedRev #' },
            // number: { label: 'Case #' },
            submitterFullName: { label: 'Submitter' },
            patientCaseType: { label: 'Case Type' },
            creationDate: { label: 'Create Date', format: formatDate },
            clientDueDate: { label: 'Due Date', format: formatDate },
            suggestedSpecialty: { label: 'Suggested specialty' },
            patientFullName: { label: 'Patient Name' },
        },
    },
    dispatch: {
        staff: {
            caseId: { label: 'MedRev #' },
            client: { label: 'Client' },
            patientCaseType: { label: 'Case Type' },
            pages: { label: 'Pages' },
            submitDate: { label: 'Submit Date', format: formatDate },
            reviewerDueDate: { label: 'Reviewer Due Date', format: formatDate },
            clientDueDate: { label: 'Due Date', format: formatDate },
            inUseByFullName: { label: 'In Use By' },
            submitterFullName: { label: 'Submitter' },
            dispatcherFullName: { label: 'Dispatcher' },
            reviewerFullName: { label: 'Reviewer' },
            suggestedSpecialty: { label: 'Suggested specialty' },
            patientFullName: { label: 'Patient Name' },
        },
    },
    review: {
        staff: {
            caseId: { label: 'MedRev #' },
            client: { label: 'Client' },
            patientCaseType: { label: 'Case Type' },
            pages: { label: 'Pages' },
            submitDate: { label: 'Submit Date', format: formatDate },
            reviewerDueDate: { label: 'Reviewer Due Date', format: formatDate },
            clientDueDate: { label: 'Due Date', format: formatDate },
            inUseByFullName: { label: 'In Use By' },
            submitterFullName: { label: 'Submitter' },
            dispatcherFullName: { label: 'Dispatcher' },
            reviewerFullName: { label: 'Reviewer' },
            suggestedSpecialty: { label: 'Suggested specialty' },
            patientFullName: { label: 'Patient Name' },
        },
    },
    qa: {
        staff: {
            caseId: { label: 'MedRev #' },
            client: { label: 'Client' },
            patientCaseType: { label: 'Case Type' },
            pages: { label: 'Pages' },
            submitDate: { label: 'Submit Date', format: formatDate },
            reviewerDueDate: { label: 'Reviewer Due Date', format: formatDate },
            clientDueDate: { label: 'Due Date', format: formatDate },
            inUseByFullName: { label: 'In Use By' },
            submitterFullName: { label: 'Submitter' },
            dispatcherFullName: { label: 'Dispatcher' },
            reviewerFullName: { label: 'Reviewer' },
            suggestedSpecialty: { label: 'Suggested specialty' },
            patientFullName: { label: 'Patient Name' },
        },
        reviewer: {
            caseId: { label: 'MedRev #' },
            client: { label: 'Client' },
            patientCaseType: { label: 'Case Type' },
            dispatcherFullName: { label: 'Dispatcher' },
            pages: { label: 'Pages' },
            reviewerDueDate: { label: 'Reviewer Due Date', format: formatDate },
        },
    },
    complete: {
        staff: {
            caseId: { label: 'MedRev #' },
            client: { label: 'Client' },
            patientCaseType: { label: 'Case Type' },
            pages: { label: 'Pages' },
            submitDate: { label: 'Submit Date', format: formatDate },
            reviewerDueDate: { label: 'Reviewer Due Date', format: formatDate },
            completionDate: { label: 'Complete Date', format: formatDate },
            inUseByFullName: { label: 'In Use By' },
            submitterFullName: { label: 'Submitter' },
            dispatcherFullName: { label: 'Dispatcher' },
            reviewerFullName: { label: 'Reviewer' },
            suggestedSpecialty: { label: 'Suggested specialty' },
            patientFullName: { label: 'Patient Name' },
        },
        client: {
            caseId: { label: 'MedRev #' },
            // number: { label: 'Case #' },
            submitterFullName: { label: 'Submitter' },
            patientCaseType: { label: 'Case Type' },
            submitDate: { label: 'Submit Date', format: formatDate },
            completionDate: { label: 'Complete Date', format: formatDate },
            suggestedSpecialty: { label: 'Suggested specialty' },
            patientFullName: { label: 'Patient Name' },
        },
    },
    closed: {
        staff: {
            caseId: { label: 'MedRev #' },
            client: { label: 'Client' },
            patientCaseType: { label: 'Case Type' },
            pages: { label: 'Pages' },
            submitDate: { label: 'Submit Date', format: formatDate },
            reviewerDueDate: { label: 'Reviewer Due Date', format: formatDate },
            completionDate: { label: 'Complete Date', format: formatDate },
            inUseByFullName: { label: 'In Use By' },
            submitterFullName: { label: 'Submitter' },
            dispatcherFullName: { label: 'Dispatcher' },
            reviewerFullName: { label: 'Reviewer' },
            lastOpenedFullName: { label: 'Last opened' },
            suggestedSpecialty: { label: 'Suggested specialty' },
            patientFullName: { label: 'Patient Name' },
        },
        client: {
            caseId: { label: 'MedRev #' },
            // number: { label: 'Case #' },
            submitterFullName: { label: 'Submitter' },
            patientCaseType: { label: 'Case Type' },
            submitDate: { label: 'Submit Date', format: formatDate },
            completionDate: { label: 'Complete Date', format: formatDate },
            lastOpenedFullName: { label: 'Last opened' },
            suggestedSpecialty: { label: 'Suggested specialty' },
            patientFullName: { label: 'Patient Name' },
        },
    },
    allCases: {
        staff: {
            caseId: { label: 'MedRev #' },
            client: { label: 'Client' },
            patientCaseType: { label: 'Case Type' },
            pages: { label: 'Pages' },
            submitDate: { label: 'Submit Date', format: formatDate },
            completionDate: { label: 'Complete Date', format: formatDate },
            inUseByFullName: { label: 'In Use By' },
            submitterFullName: { label: 'Submitter' },
            dispatcherFullName: { label: 'Dispatcher' },
            reviewerFullName: { label: 'Reviewer' },
            stage: { label: 'Stage' },
            suggestedSpecialty: { label: 'Suggested specialty' },
            patientFullName: { label: 'Patient Name' },
        },
        client: {
            caseId: { label: 'MedRev #' },
            submitterFullName: { label: 'Submitter' },
            patientCaseType: { label: 'Case Type' },
            submitDate: { label: 'Submit Date', format: formatDate },
            completionDate: { label: 'Complete Date', format: formatDate },
            stage: { label: 'Stage' },
            suggestedSpecialty: { label: 'Suggested specialty' },
            patientFullName: { label: 'Patient Name' },
        },
    },
    flagged: {
        staff: {
            caseId: { label: 'MedRev #' },
            client: { label: 'Client' },
            patientCaseType: { label: 'Case Type' },
            pages: { label: 'Pages' },
            submitDate: { label: 'Submit Date', format: formatDate },
            completionDate: { label: 'Complete Date', format: formatDate },
            inUseByFullName: { label: 'In Use By' },
            submitterFullName: { label: 'Submitter' },
            dispatcherFullName: { label: 'Dispatcher' },
            reviewerFullName: { label: 'Reviewer' },
            stage: { label: 'Stage' },
            suggestedSpecialty: { label: 'Suggested specialty' },
            patientFullName: { label: 'Patient Name' },
        },
        client: {
            caseId: { label: 'MedRev #' },
            submitterFullName: { label: 'Submitter' },
            patientCaseType: { label: 'Case Type' },
            submitDate: { label: 'Submit Date', format: formatDate },
            completionDate: { label: 'Complete Date', format: formatDate },
            stage: { label: 'Stage' },
            suggestedSpecialty: { label: 'Suggested specialty' },
            patientFullName: { label: 'Patient Name' },
        },
        reviewer: {
            caseId: { label: 'MedRev #' },
            client: { label: 'Client' },
            patientCaseType: { label: 'Case Type' },
            dispatcherFullName: { label: 'Dispatcher' },
            pages: { label: 'Pages' },
            reviewerDueDate: { label: 'Reviewer Due Date', format: formatDate },
        },
    },
    inProcess: {
        client: {
            caseId: { label: 'MedRev #' },
            // number: { label: 'Case #' },
            submitterFullName: { label: 'Submitter' },
            patientCaseType: { label: 'Case Type' },
            submitDate: { label: 'Submit Date', format: formatDate },
            clientDueDate: { label: 'Due Date', format: formatDate },
            stage: { label: 'Stage' },
            suggestedSpecialty: { label: 'Suggested specialty' },
            patientFullName: { label: 'Patient Name' },
        },
    },
    available: {
        reviewer: {
            caseId: { label: 'MedRev #' },
            client: { label: 'Client' },
            patientCaseType: { label: 'Case Type' },
            dispatcherFullName: { label: 'Dispatcher' },
            pages: { label: 'Pages' },
            reviewerDueDate: { label: 'Reviewer Due Date', format: formatDate },
        },
    },
    accepted: {
        reviewer: {
            caseId: { label: 'MedRev #' },
            client: { label: 'Client' },
            patientCaseType: { label: 'Case Type' },
            dispatcherFullName: { label: 'Dispatcher' },
            pages: { label: 'Pages' },
            reviewerDueDate: { label: 'Reviewer Due Date', format: formatDate },
        },
    },
    closedCases: {
        reviewer: {
            caseId: { label: 'MedRev #' },
            client: { label: 'Client' },
            patientCaseType: { label: 'Case Type' },
            dispatcherFullName: { label: 'Dispatcher' },
            pages: { label: 'Pages' },
            completionDate: { label: 'Complete Date', format: formatDate },
            reviewerTime: { label: 'Reviewer Time' },
            payment: { label: 'Payment' },
            lastOpenedFullName: { label: 'Last opened' },
        },
    },
    closedCasesThisMonth: {
        reviewer: {
            caseId: { label: 'MedRev #' },
            client: { label: 'Client' },
            patientCaseType: { label: 'Case Type' },
            dispatcherFullName: { label: 'Dispatcher' },
            pages: { label: 'Pages' },
            completionDate: { label: 'Complete Date', format: formatDate },
            reviewerTime: { label: 'Reviewer Time' },
            payment: { label: 'Payment' },
            lastOpenedFullName: { label: 'Last opened' },
        },
    },
    closedCasesLastMonth: {
        reviewer: {
            caseId: { label: 'MedRev #' },
            client: { label: 'Client' },
            patientCaseType: { label: 'Case Type' },
            dispatcherFullName: { label: 'Dispatcher' },
            pages: { label: 'Pages' },
            completionDate: { label: 'Complete Date', format: formatDate },
            reviewerTime: { label: 'Reviewer Time' },
            payment: { label: 'Payment' },
            lastOpenedFullName: { label: 'Last opened' },
        },
    },
};

export const {
    draft,
    dispatch,
    review,
    qa,
    complete,
    closed,
    allCases,
    flagged,
    inProgress,
    inProcess,
    available,
    accepted,
    closedCases,
    closedCasesThisMonth,
    closedCasesLastMonth,
} = patientCaseColumns;
